import request from '@/utils/request';

//列表
export function fetchList(data) {
  return request({
    url : '/user/preview/list.php',
    method : 'post',
    data : data
  })
}

//删除
export function deleteUserPreview(data) {
  return request({
    url : '/user/preview/delete.php',
    method : 'post',
    data : data
  })
}
//批量删除
export function batchDeleteUserPreview(data) {
  return request({
    url : '/user/preview/deleteBatch.php',
    method : 'post',
    data : data
  })
}
